import React, { Component } from "react";
import { Container, Row, Col, Label, Input, Button, InputGroup, FormGroup, FormFeedback } from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png"
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Footer from "../../Components/Footer";
import GoogleMap from "../../Components/GoogleMap";
import EditProfile from "./EditProfile";
import ToastBox from "../../Components/ToastBox/ToastBox";
import { Post, Get } from "../../../utilities/axiosRequest";
import axios from 'axios';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
const phoneRegExp = /^(9665|\+9665|5|05|00966)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Enter valid name")
        .required("Name is required"),

    mobileNumber: Yup.string()
        .required("Mobile number is required")
        .matches(phoneRegExp, 'Phone number is not valid'),

    emailAddress: Yup.string()
        .email("Please enter valid e-mail")
        .required("E-mail is required"),
    password: Yup.string()
        .trim("No white space allow in starting")
        .required("Password is required")
        .min(8, 'Seems a bit short...')
        .max(14, 'We prefer insecure system, try a shorter password.'),
    confirmPass: Yup.string()
        .trim("No white space allow in starting")
        .required("Confirm Password is required")
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
        }),
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Username is Required'),
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];

class MyProfile extends Component {



    state = {
        showPassword: false,
        country: [],
        city: [],
        lat: '21.4249',
        lng: '39.850',
        submitvalues: [],
        spinnerLoading: false,
        show: false,
        userDuplicate: false,
        country_code: '966',
        country_id: 1,
        city_id: 1,
        userInfo: [],
        customer_name: "",

    }

    setShow = (status) => {
        this.setState({ show: status });

    }
    loginHandler = (values) => {

        console.log("sign up", values.name);
        values.lat = this.state.lat;
        values.lng = this.state.lng;
        axios.post('https://api.niddaa.com/api/v1/user/update-user', values, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then(result => {
                console.log("respons....", result.data.message)
                if (result.data.messsage != "") {
                    this.setState({ message: result.data.message, show: true })
                } else {
                    this.setState({ message: result.error, show: true })
                }


            });

        /** Post("ontimefix.com/backend/api/v1/countries",values).then(result => { result.countries.forEach(function(data, idx) {
              countries.push({'value':data.country_id, 'label':data.country_name});   
              });**/
        // localStorage.setItem("token", "cjkacnjdajbhjabfhknsnqwopodkmcnmnzx,clkoakdlMDKNDMNCKJNKJASNCABSDKJADNjabdkj");
        // this.props.history.replace("/");
    }



    validateUsername = (username) => {

        axios.get(`https://api.niddaa.com/api/v1/user/duplicate-user?username=${username}`).then(result => {

            if (result.data.duplicate) {
                this.setState({ userDuplicate: true })

            } else {

                this.setState({ userDuplicate: false })
            }


        }


        );
    }


    forgotPassword = () => {
        this.props.history.push("/login")
    }

    updateLoctaionCord = (lat, long) => {
        console.log("Latitude..", lat);
        this.setState({ lat: lat, lng: long })
    }



    changearabicToEng = (str) => {
        const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
        const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
        const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

        str = str.split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
            englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
        this.setState({ mobileNumber: str })
        return str;
    };

    changeArabic = (event, setFieldValue) => {
        console.log("event", event)
        var number = this.changearabicToEng(event.target.value);
        console.log("afetr conversion", number)
        setFieldValue('mobileNumber', number)
    }

    filterCities = (selected, setFieldValue) => {
        const cities = [];
        setFieldValue('country_id', selected);
        this.setState({ country_id: selected });

        Get(`https://api.niddaa.com/api/v1/country-info?&country_id=${selected}`).then(result => {

            this.setState({ country_code: result.countryinfo[0].country_code, support_number: result.countryinfo[0].support_number })
        }
        );

        Get(`https://api.niddaa.com/api/v1/cities?&country_id=${selected}`).then(result => {
            result.cities.forEach(function (data, idx) {
                cities.push({ 'value': data.city_id, 'label': i18next.language == "en" ? data.city_name : data.city_name_ar });
            });
            this.setState({ city: cities })
        }
        );
        // Get(`https://api.niddaa.com/api/v1/services?&city_id=${selected}`).then(result => this.setState({services: result.cityservices }));  

    }




    componentDidMount() {
        const countries = [];
        const cities = [];
        Get("https://api.niddaa.com/api/v1/countries").then(result => {
            result.countries.forEach(function (data, idx) {
                countries.push({ 'value': data.country_id, 'label': data.country_name });
            });

            this.setState({ country: countries });
        });
        Get("https://api.niddaa.com/api/v1/cities").then(result => {
            result.cities.forEach(function (data, idx) {
                cities.push({ 'value': data.city_id, 'label': data.city_name });
            });
            this.setState({ city: cities })
        }
        );


        Get("https://api.niddaa.com/api/v1/user/my-profile").then(result => {
            console.log("user info..", result.userinfo)
            this.setState({ userInfo: result.userinfo[0] });

        });

    };





    render() {
        const { t } = this.props;
        const { showPassword, country, city, lat, lng, message, show, spinnerLoading, userDuplicate, country_code, userInfo, filterCities, changearabicToEng } = this.state;



        {/* if (localStorage.getItem("token")) return <Redirect to="/" from="*" /> **/ }
        return (
            <>

                <ToastBox show={show} message={message} setShow={this.setShow} />
                <Container>
                    <Row>
                        <EditProfile userInfo={userInfo} country_code={country_code} loginHandler={this.loginHandler} userDuplicate={userDuplicate} updateLoctaionCord={this.updateLoctaionCord} showPassword={showPassword} lat={lat} lng={lng} country={country} city={city} />
                        <Col md="6" className="d-none d-md-block pt-5 mt-5">
                            <img src={sideImage} alt="" className="img-fluid mt-5 pt-5" />
                        </Col>
                        {message !== "" &&
                            <Col sm="12" className="order-4">
                                <p className="m-0 text-muted text-center text-dark-green">
                                    {message

                                    }

                                </p>
                            </Col>}
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
};

export default withTranslation()(MyProfile)
